import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "targetKet";
const modelPath = "/programstudi/targetketerampilan/";

class TargetKeterampilan extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        keterampilan: null,
        target_observasi: null,
        target_supervisi: null
    };
    let requiredFields = [
      "angkatan", "keterampilan", "target_observasi", 
      "target_supervisi"
    ];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default TargetKeterampilan;